/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';
import card1 from '../assets/brand2.png';
import card2 from '../assets/brand1.png';
import card3 from '../assets/brand3.png';

const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const DAO = () => {


	return (
		<div class="daoMainBg">
			<div class="daoMain">
				<div class="title">Sv Luxury DAO Properties</div>
				<p>Unlocking fractionalized real estate through blockchain technology with SV Luxury DAO. Membership offers a revolutionary approach to property ownership. This innovative system democratizes access to luxury real estate markets, enabling all levels of members to participate in high-value properties. </p>
				<p>By leveraging the transparency, security, and efficiency of blockchain, SV Luxury DAO Membership facilitates fractional ownership, allowing members to participate in real estate with lower capital requirements.</p>
				<p>This method not only simplifies real estate ownership process but also provides liquidity and flexibility, digital access pass can be easily traded on digital platforms. Moreover, the decentralized nature of DAOs ensures that all members have a voice in decision-making processes, fostering a community-driven approach to growth.</p>
				</div>

				<div className='mainCards'>
					<div className='mainCardsSub'>
						<img src={card1} />
						<div><span className='bold'>Bitcoin mansion</span> <br /> (property value $3.5M) <br /> $22k-$39K/Month <br /> Management Net</div>
					</div>

					<div className='mainCardsSub'>
						<img src={card2} />
						<div><span className='bold'>Ensenada</span> <br /> ($20M developed) <br /> $50K-$64K/Month <br /> Management Net</div>
					</div>

					<div className='mainCardsSub'>
						<img src={card3} />
						<div><span className='bold'>Playa La Ventana</span> <br /> ($22M Developed) <br /> $70K-$88K/Month <br /> management Net</div>
					</div>
			</div>
		</div >
	)

}
export default DAO;
