/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';

const ops = () => {
	window.open("#");
}

const discord = () => {
	window.open("https://discord.com/invite/tknBv7Xwuj");
}

const homeLink = () => {
	window.open("#");
}


const Vision = () => {


	return (
		<div class="visionMain">
			<div class="title">Our Vision</div>
			
			<div className='visionSub'>
				<div>
					<li>Democratizing real estate through blockchain</li>
					<li>Building a community of forward-thinking investors</li>
					<li>Creating a sustainable and profitable ecosystem in El Salvador</li>
					<li>Fractionalize ownership for our community members </li>
					<li>Teaching and duplicating Dao's for our community members </li>
				</div>
				<img src="https://sv.luxury/videos/gif.gif" />
			</div>
		</div >
	)

}
export default Vision;
