import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import ID5P from './Pages/ID5';
import ID6P from './Pages/ID6';
import ID7P from './Pages/ID7';

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/Product_5' element={<ID5P />} />
					<Route path='/Product_6' element={<ID6P />} />
					<Route path='/Product_7' element={<ID7P />} />

				</Routes>
			</BrowserRouter>
			<div>


			</div>
		</div>




	)
}


export default App;
