/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useMemo, useState } from 'react';

const Why = () => {

	return (
		<div class="whyMain">
		<div>
			<div class="title">WHY EL SALVADOR?</div>

			<p>FIRST COUNTRY TO ADOPT BITCOIN AS A LEGAL TENDER</p>
			<p>GROWING ECONOMY WITH A FOCUS ON TECHNOLOGICAL INNOVATION</p>
			<p>BEAUTIFUL LANDSCAPES AND BOOMING TOURISM INDUSTRY</p>
			<p>TRANSFORMATION OF LEGAL FRAMEWORK FOR NFTS, DAOS, AND TOKENIZATION</p>
			<p>SECURE AND TRANSPARENT TRANSACTIONS</p>
			<p>DAO GOVERNANCE MODEL FOR PROJECT DECISIONS</p>
			<p>TOKENIZATION OF PROPERTY ASSETS</p>
			<p>NETWORKING PRIVATE EXCLUSIVE EVENTS AND MEET & GREETS</p>

			<iframe className='videoWidth' src="https://www.youtube.com/embed/o58M6240ugQ?si=hJ9o8IRDWekjQmQP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

		</div >
		</div >
	)

}
export default Why;
